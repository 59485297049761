import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import {Helmet} from "react-helmet";

export const IndexPageTemplate = ({ banner, questions, testimonials, intro }) => {
  const testimonialsCount = Array(Math.floor(testimonials.list.length / 4)).fill(1);

  setTimeout(() => {
    const $ = window.$;
    if ($) {
      window.document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) { e.preventDefault(); });
      });
      $(".nav-tabs a").click(function () {
        $(this).tab('show');
      });

      $('.carousel-item', '.show-neighbors').each(function () {
        var next = $(this).next();
        if (!next.length) {
          next = $(this).siblings(':first');
        }
        next.children(':first-child').clone().appendTo($(this));
      }).each(function () {
        var prev = $(this).prev();
        if (!prev.length) {
          prev = $(this).siblings(':last');
        }
        prev.children(':nth-last-child(2)').clone().prependTo($(this));
      });
      $('.show_on_mobile .carousel').carousel({
        interval: 3000,
        cycle: true
      });
    }
  }, 1000);

  return (
    <main role="main" className="main-page">
      <Helmet>
        <title>Pete</title>
      </Helmet>
      <div>
        {/* Begin Banner */}
        <div className="jumbotron no-apps mb-0" style={{"backgroundImage": `linear-gradient(rgba(0,0,0, 0.3), rgba(0,0,0, 0.3)), url(${banner.bannerImage.childImageSharp ? banner.bannerImage.childImageSharp.fluid.src : banner.bannerImage})`}}>
          <div className="container text-light">
            <h1 className="display-5 text-white mb-3">
              Physical therapists who come<br /> to you, wherever you are.
            </h1><a className="btn btn-gr pl-3 pr-3 visit-btn mr-3 fw-6 schedule" data-toggle="modal" data-target="#FormModalCenter" role="button">{banner.btnTitle}</a><br />
            <span style={{fontSize: '17px', fontWeight: 'bold'}}>or call <a className="text-white" href={`tel:+{banner.phone}`} role="button">{banner.phone}</a></span>
          </div>
        </div>
        <div className="bg-gray no-add-logo-car">
          <div className="container">
            <div className="row">
              <div className="col-md-3 text-right">
                <span className="cov-ins">Covered insurance:</span>
              </div>
              <div className="col-md-9">
                <div id="carouselLogo" className="carousel slide" data-ride="carousel">
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-2"><img src="/assets/img/no-app-logo-1.png" alt="" /></div>
                        <div className="col-md-2"><img src="/assets/img/no-app-logo2.png" alt="" /></div>
                        <div className="col-md-2"><img src="/assets/img/no-app-logo3.png" alt="" /></div>
                        <div className="col-md-2"><img src="/assets/img/no-app-logo-4.png" alt="" /></div>
                        <div className="col-md-2"><img src="/assets/img/no-app-logo-5.png" alt="" /></div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-2"><img src="/assets/img/no-app-logo-1.png" alt="" /></div>
                        <div className="col-md-2"><img src="/assets/img/no-app-logo2.png" alt="" /></div>
                        <div className="col-md-2"><img src="/assets/img/no-app-logo3.png" alt="" /></div>
                        <div className="col-md-2"><img src="/assets/img/no-app-logo-4.png" alt="" /></div>
                        <div className="col-md-2"><img src="/assets/img/no-app-logo-5.png" alt="" /></div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-2"><img src="/assets/img/no-app-logo-1.png" alt="" /></div>
                        <div className="col-md-2"><img src="/assets/img/no-app-logo2.png" alt="" /></div>
                        <div className="col-md-2"><img src="/assets/img/no-app-logo3.png" alt="" /></div>
                        <div className="col-md-2"><img src="/assets/img/no-app-logo-4.png" alt="" /></div>
                        <div className="col-md-2"><img src="/assets/img/no-app-logo-5.png" alt="" /></div>
                      </div>
                    </div>
                  </div><a className="carousel-control-next ca-shadow-sm rounded-circle" href="#carouselLogo" role="button" data-slide="next">→</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Banner */}
        {/* Begin Second section */}
        <div className="container pt-5 pb-5 ph-second-wrap text-center">
          <h2 className="py-3">{intro.title}</h2>
          <p className="p-mob-sm py-3">{intro.subtitle}</p><br />
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="text-center">
                <div className="acc feature-img-bg mx-auto"><img className="circle-icon" src="/assets/icons/landing/1.png" alt="" /></div>
                <h3>{intro.item1.title}</h3>
                <p>{intro.item1.description}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-center">
                <div className="acc feature-img-bg mx-auto"><img className="circle-icon" src="/assets/icons/landing/2.png" alt="" /></div>
                <h3>{intro.item2.title}</h3>
                <p>{intro.item2.description}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-center">
                <div className="acc feature-img-bg mx-auto"><img className="circle-icon" src="/assets/icons/landing/3.png" alt="" /></div>
                <h3>{intro.item3.title}</h3>
                <p>{intro.item3.description}</p>
              </div>
            </div>
          </div>
        </div>
        {/* End Second section */}
        {/* Begin Fourth Section */}
        <div className="container-fluid text-center license-the">
          <div className="container">
            <h2>Our Licensed Therapists</h2>
            <p>Ready to treat you whenever and wherever you need us. Say hello to the faces of PeteHealth.</p>
            <div className="container">
              <div className="row">
                <div className="w-100">
                  {/* Top content */}
                  <div id="trainercarouselExampleIndicators" className="ph-carousel carousel slide hide_on_mobile" data-ride="carousel">
                    <div className="carousel-inner">
                      <div id="ptcards">
                        <div>
                          <div className="carousel-item active">
                            <div className="row" style={{justifyContent: 'space-evenly'}}>
                              <div className="col-md-3 col-sm-12">
                                <button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalalex">
                                  <div className="team-wrap card">
                                    <img src="/assets/newimg/trainer2.png" className="img-fluid mx-auto d-block" alt="img2" />
                                    <div className="cont">
                                      <h3>Alex Lui, DPT</h3>
                                      <small style={{marginTop: 0, paddingTop: 0}}>
                                        Manual Therapy,
                                        Chronic Neck &amp; Low Back Pain,
                                        Post-Op Rehab
                                      </small>
                                    </div>
                                  </div>
                                </button>
                              </div>
                              <div className="col-md-3 col-sm-12">
                                <button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalamy">
                                  <div className="team-wrap card">
                                    <img src="/assets/newimg/trainer4.png" className="img-fluid mx-auto d-block" alt="img2" />
                                    <div className="cont">
                                      <h3>Amy Smith, DPT</h3>
                                      <small style={{marginTop: 0, paddingTop: 0}}>
                                        Manual Therapy,
                                        Spinal Rehab,
                                        Shoulder Injuries,
                                        Post-Op Rehab
                                      </small>
                                    </div>
                                  </div>
                                </button>
                              </div>
                              <div className="col-md-3 col-sm-12">
                                <button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModaldave">
                                  <div className="team-wrap card">
                                    <img src="/assets/newimg/trainer5.png" className="img-fluid mx-auto d-block" alt="img2" />
                                    <div className="cont">
                                      <h3>Dave Halberg, PT</h3>
                                      <small style={{marginTop: 0, paddingTop: 0}}>
                                        Post-Surgical Rehab,
                                        Functional Training,
                                        Orthopedics
                                      </small>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="carousel-item">
                            <div className="row" style={{justifyContent: 'space-evenly'}}>
                              <div className="col-md-3 col-sm-12">
                                <button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalcamille">
                                  <div className="team-wrap card">
                                    <img src="/assets/newimg/trainer3.png" className="img-fluid mx-auto d-block" alt="img2" />
                                    <div className="cont">
                                      <h3>Camille Choi, DPT</h3>
                                      <small style={{marginTop: 0, paddingTop: 0}}>
                                        Cupping/Myofascial Decompression,
                                        Joint &amp; Spine Post-Op Rehab
                                      </small>
                                    </div>
                                  </div>
                                </button>
                              </div>
                              <div className="col-md-3 col-sm-12">
                                <button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalted">
                                  <div className="team-wrap card">
                                    <img src="/assets/newimg/trainer1.png" className="img-fluid mx-auto d-block" alt="img2" />
                                    <div className="cont">
                                      <h3>Ted Steben, MPT</h3>
                                      <small style={{marginTop: 0, paddingTop: 0}}>
                                        Post-Op Rehab,
                                        Manual Therapy,
                                        Shoulder Injuries,
                                        Knee Injuries
                                      </small>
                                    </div>
                                  </div>
                                </button>
                              </div>
                              <div className="col-md-3 col-sm-12">
                                <button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalcindy">
                                  <div className="team-wrap card">
                                    <img src="/assets/newimg/trainer7.png" className="img-fluid mx-auto d-block" alt="img2" />
                                    <div className="cont">
                                      <h3>Cindy Glaser, DPT</h3>
                                      <small style={{marginTop: 0, paddingTop: 0}}>
                                        Orthopedics,
                                        Spine Rehabilitation,
                                        Chronic Pain Treatment,
                                        Pilates
                                      </small>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div><a className="carousel-control-prev ca-shadow-sm" href="#trainercarouselExampleIndicators" role="button" data-slide="prev">←</a>
                    <a className="carousel-control-next ca-shadow-sm" href="#trainercarouselExampleIndicators" role="button" data-slide="next">→</a>
                  </div>
                  {/* Mobile Carousel */}
                  {/* For Mobile view Slider Start */}
                  <div className="bd-example show_on_mobile">
                    <div id="trainercarouselExampleIndicatorsmob" className="carousel slide show-neighbors" data-ride="carousel">
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <div className="item__third">
                            <div className="team-wrap card">
                              <button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalalex"><img src="/assets/newimg/trainer2.png" className="img-fluid mx-auto d-block" alt="img1" /></button>
                              <div className="cont">
                                <h3><button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalalex">Alex Lui,
                                  DPT</button></h3>
                                <p><button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalalex">Manual Therapy,
                                  Chronic Pain Treatment, Orthopedics</button></p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="item__third">
                            <div className="team-wrap card">
                              <button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalamy"><img src="/assets/newimg/trainer4.png" className="img-fluid mx-auto d-block" alt="img2" /></button>
                              <div className="cont">
                                <h3><button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalamy">Amy Smith,
                                  DPT</button></h3>
                                <p><button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalamy">Manual Therapy,
                                  Spinal Rehab, Shoulder injuries, Post-Op Rehab</button></p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="item__third">
                            <div className="team-wrap card">
                              <button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModaldave"><img src="/assets/newimg/trainer5.png" className="img-fluid mx-auto d-block" alt="img1" /></button>
                              <div className="cont">
                                <h3><button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModaldave">Dave Halberg,
                                  PT</button></h3>
                                <p><button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModaldave">Biomechanical
                                  Analysis, Post-Surgical Rehab, Functional training, Orthopedic
                                  Rehab</button></p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="item__third">
                            <div className="team-wrap card">
                              <button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalcamille"><img src="/assets/newimg/trainer3.png" className="img-fluid mx-auto d-block" alt="img1" /></button>
                              <div className="cont">
                                <h3><button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalcamille">Camille Choi,
                                  DPT</button></h3>
                                <p><button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalcamille">Functional
                                  Exercise Rehab, Cupping/Myofascial decompression, Joint And Spine
                                  Post-op Rehab</button></p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="item__third">
                            <div className="team-wrap card">
                              <button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalted"><img src="/assets/newimg/trainer1.png" className="img-fluid mx-auto d-block" alt="img1" /></button>
                              <div className="cont">
                                <h3 className="text-left"><button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalted">Ted Steben,
                                  MPT</button></h3>
                                <p className="text-left"><button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalted">Post-Op Rehab,
                                  Manual Therapy, Shoulder injuries, Knee injuries</button></p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="item__third">
                            <div className="team-wrap card">
                              <button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalcindy"><img src="/assets/newimg/trainer7.png" className="img-fluid mx-auto d-block" alt="img1" /></button>
                              <div className="cont">
                                <h3><button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalcindy">Cindy Glaser,
                                  DPT</button></h3>
                                <p><button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalcindy">Orthopedics, Spine
                                  Rehabilitation, Chronic Pain Treatment, Movement Retraining,
                                  Pilates</button></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ul className="carousel-indicators">
                        <li data-target="#trainercarouselExampleIndicatorsmob" data-slide-to={0} className="active">a</li>
                      </ul><a className="carousel-control-prev ca-shadow-sm" href="#trainercarouselExampleIndicatorsmob" role="button" data-slide="prev">←</a> <a className="carousel-control-next ca-shadow-sm" href="#trainercarouselExampleIndicatorsmob" role="button" data-slide="next">→</a>
                    </div>
                  </div>
                  {/* End */}
                </div>
              </div>
            </div>
          </div><br />
          <a className="btn btn-gr pl-3 pr-3 visit-btn mr-3 fw-6 schedule" data-toggle="modal" data-target="#FormModalCenter" role="button">Schedule visit</a><br />
          <div className="f-7 fw-6 text-dark font-weight-bold hiw-call" style={{marginLeft: '-15px'}}>
            or call <a className="text-dark" href="tel:+18888590145" role="button">888-859-0145</a>
          </div><br />
        </div>
        {/* End Fourth Section */}
        {/* Begin Fifth Section */}
        <div id="pricing_and_ins" className="bg-gray text-center">
          <div className="medi-wrap">
            <div className="row mx-auto row-1">
              <div className="col-md-5 text-left">
                <h2> Good news. If you're a patient who qualifies, PeteHealth is fully covered by Medicare.
                  <p />
                  <img src="/assets/newimg/medicaretrans.png" style={{width: '200px', margin: 'auto'}} />
                </h2></div>
              <div className="col-md-7 text-left phy-med-7">
                <div className="comm-ins">
                  <div className="comm-inner">
                    <h2> We'll take your insurance </h2>
                    <p>If you have health insurance, chances are you'll be covered. Here are
                      a few of the insurance companies that we accept.</p>
                    <p className="com-images-wrap"><span><img className="img-fluid" src="/assets/img/ph-cm-1.png" alt="" /></span><span><img src="/assets/img/ph-cm-2.png" alt="" /></span><span><img src="/assets/img/ph-cm-3.png" alt="" /></span></p>
                    <p className="com-images-wrap"><span><img src="/assets/img/ph-cm-4.png" alt="" /></span><span><img src="/assets/img/ph-cm-6.png" alt="" /></span></p>
                    <br />
                    <h2>Or pay just a flat fee of $175.</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        {/* End Fifth Section */}
        {/* Begin How it works */}
        <div className="container pt-2 pb-5 why-doctor">
          <div className="row">
            <div className="col-md-5">
              <h2 className="text-left">Physicians love referring<br />
                patients to us. Here's why:</h2>
              <p className="text-left">The most respected doctors trust us with their treatment plans
                because of our patient-centered service approach and our utilization of the latest
                healthcare-centric technologies. Learn what makes PeteHealth stand out above the rest for
                physicians.</p>
            </div>
            <div className="col-md-7 md-none">
              <div className="row">
                <div className="col-md-4 text-center">
                  <div className="point-img-wrap position-relative text-center no-apps-5"><img src="/assets/icons/landing/4.png" alt="" className="position-absolute" /></div>
                  <div className="points-text pt-2">
                    <h3 style={{marginTop: '0.8rem', marginBottom: '.99rem'}}>Amazing care</h3>
                  </div>
                </div>
                <div className="col-md-4 text-center">
                  <div className="point-img-wrap position-relative text-center no-apps-5"><img src="/assets/icons/landing/5.png" alt="" className="position-absolute" /></div>
                  <div className="points-text pt-2">
                    <h3 style={{marginTop: '0.8rem', marginBottom: '.99rem'}}>Positive clinical
                      outcomes</h3>
                  </div>
                </div>
                <div className="col-md-4 text-center">
                  <div className="point-img-wrap position-relative text-center no-apps-5"><img src="/assets/icons/landing/6.png" alt="" className="position-absolute" /></div>
                  <div className="points-text pt-2">
                    <h3 style={{marginTop: '0.8rem', marginBottom: '.99rem'}}>Higher patient
                      satisfaction</h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 text-center">
                  <div className="points-text pt-2">
                    <p>Physicians know we are equipped with complete physical therapy offering.</p>
                  </div>
                </div>
                <div className="col-md-4 text-center">
                  <div className="points-text pt-2">
                    <p>We become an integral part of your treatment plan, and work with your doctor to
                      optimize your health.</p>
                  </div>
                </div>
                <div className="col-md-4 text-center">
                  <div className="points-text pt-2">
                    <p>What can we say? Patients like us. They really, really like us.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 desktop-none">
              <div className="row">
                <div className="col-md-4 text-center">
                  <div className="point-img-wrap position-relative text-center no-apps-5"><img src="/assets/icons/landing/4.png" alt="" className="position-absolute" /></div>
                  <div className="points-text pt-2">
                    <h3 style={{marginTop: '0.8rem', marginBottom: '.99rem'}}>Amazing care</h3>
                  </div>
                  <div className="points-text pt-2">
                    <p>Physicians know we are equipped with complete physical therapy offering.</p>
                  </div>
                </div>
                <div className="col-md-4 text-center">
                  <div className="point-img-wrap position-relative text-center no-apps-5"><img src="/assets/icons/landing/5.png" alt="" className="position-absolute" /></div>
                  <div className="points-text pt-2">
                    <h3 style={{marginTop: '0.8rem', marginBottom: '.99rem'}}>Positive clinical
                      outcomes</h3>
                  </div>
                  <div className="points-text pt-2">
                    <p>We become an integral part of your treatment plan, and work with your doctor to
                      optimize your health.</p>
                  </div>
                </div>
                <div className="col-md-4 text-center">
                  <div className="point-img-wrap position-relative text-center no-apps-5"><img src="/assets/icons/landing/6.png" alt="" className="position-absolute" /></div>
                  <div className="points-text pt-2">
                    <h3 style={{marginTop: '0.8rem', marginBottom: '.99rem'}}>Higher patient
                      satisfaction</h3>
                  </div>
                  <div className="points-text pt-2">
                    <p>What can we say? Patients like us. They really, really like us.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-4">
              <div className="text-center ca-shadow-sm rew">
                <div className="thumbnaildiv" style={{background: 'url("./assets/newimg/thumbnail1.png")'}} />
                <h3 className="mt-3">Jason Snibbe, MD</h3>
                <p>"This is where modern physical therapy is headed. Care built around convenience
                  and accessibility."</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-center ca-shadow-sm rew">
                <div className="thumbnaildiv" style={{background: 'url("./assets/newimg/thumbnail2.png")'}} />
                <h3 className="mt-3">Sanjay Khurana, MD</h3>
                <p>"I know I can refer to PeteHealth without regret. It brings me peace of mind to
                  know we have a true partner."</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-center ca-shadow-sm rew">
                <div className="thumbnaildiv" style={{background: 'url("./assets/newimg/thumbnail3.png")'}} />
                <h3 className="mt-3">Micheal Port, MD</h3>
                <p>"What more can I say? The care. The results. The happy patients. PeteHealth
                  really is the total package."</p>
              </div>
            </div>
          </div>
        </div>
        {/* End How it works */}
        {/* Begin Testimonials */}
        <div className="container-fluid bg-gray mt-4 pt-5 pb-5 text-center no-app-car">
          <div className="container">
            <h2>{testimonials.title}</h2>
            <p>{testimonials.subtitle}</p>
            <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
              <div className="carousel-inner">
                {
                  testimonialsCount.map((slide, slideId) => {
                    return (
                      <div className={`carousel-item ${slideId === 0 && 'active'}`} key={slideId}>
                        <div className="row mb-md-4">
                          {
                            testimonials.list.map((item, i) => {
                              if (i < 4 * (slideId + 1) && (i >= 4 * slideId)) {
                                return (
                                  <div className="col-md-6" style={{height: '270px'}} key={i}>
                                    <div className="card ca-shadow-sm border-0 p-4 py-5" style={{height: '240px'}}>
                                      <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
                                      <p>{item.text}</p>
                                      <h3>{item.name}</h3>
                                    </div>
                                  </div>
                                )
                              }
                            })
                          }
                        </div>
                      </div>
                    );
                  })
                }
              </div><a className="carousel-control-prev ca-shadow-sm rounded-circle white-bg" href="#carouselExampleControls" role="button" data-slide="prev">←</a> <a className="carousel-control-next ca-shadow-sm rounded-circle white-bg" href="#carouselExampleControls" role="button" data-slide="next">→</a>
            </div>
            <div id="mob-carousel" className="carousel slide" data-ride="carousel">
              <div>
                <ul className="carousel-indicators" />{/* The slideshow */}
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="card ca-shadow-sm border-0 p-4">
                      <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
                      <p>
                        After shoulder labrum repair surgery, and trouble finding a physical therapist
                        in my area, and I decided to try out Pete Health. That might have been the best
                        decision I made in my shoulder rehab. Plus, I didn't have to drive in traffic.
                      </p>
                      <h3>Cassie L.</h3>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="card ca-shadow-sm border-0 p-4">
                      <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
                      <p>
                        I am a physician that tore my left meniscus earlier this year. My PM &amp; R
                        doctor recommended PT over Surgery and gave me you all as a recommendation.
                        He knew I also had a busy work schedule and that you'd come to me.
                      </p>
                      <h3>Dr. Javier</h3>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="card ca-shadow-sm border-0 p-4">
                      <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
                      <p>
                        My treatment sessions have been great! My therapist has taught me how to
                        strengthen my core and abs to take pressure off of my back. I've been struggling with
                        nerve pain and after 5 sessions, it's so much better already.
                      </p>
                      <h3>Don G.</h3>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="card ca-shadow-sm border-0 p-4">
                      <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
                      <p>
                        I can't say enough good things. I love that my therapist emails me videos of the
                        stretches and exercises to do in between sessions and on my own. I recommend
                        Pete Health very highly to anyone experiencing back and sciatica.
                      </p>
                      <h3>Kendra M.</h3>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <a className="btn btn-gr pl-3 pr-3 visit-btn mr-3 fw-6 schedule mt-md-5" data-toggle="modal" data-target="#FormModalCenter" role="button">Schedule visit</a>
            <br />
            <div className="f-7 fw-6 text-dark font-weight-bold hiw-call" style={{marginLeft: '-15px'}}>
              or call <a className="text-dark" href="tel:+18888590145" role="button">888-859-0145</a>
            </div>
          </div>
        </div>
        {/* End Testimonials */}
        {/* Begining FAQ Section */}
        <div className="row faq-row md-text-white" id="faq">
          <div className="col-md-6 no-app-faq">
            <h2 className="mb-3 text-left md-text-white">{questions.title}</h2>
            <p className="text-left">{questions.subtitle}</p><br />
            {
              questions.list.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <h3 className="mb-3 text-left md-text-white">{item.title}</h3>
                    <p>{item.text}</p><br />
                  </React.Fragment>
                )
              })
            }
          </div>
          <div className="col-md-6 no-apps-aside text-right banner2" style={{"backgroundImage": `url(${questions.image.childImageSharp ? questions.image.childImageSharp.fluid.src : questions.image})`}} />
        </div>
      </div>
    </main>
  );
};

IndexPageTemplate.propTypes = {
  banner: PropTypes.object,
  questions: PropTypes.object,
  testimonials: PropTypes.object,
  intro: PropTypes.object,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <IndexPageTemplate
        banner={frontmatter.banner}
        questions={frontmatter.questions}
        testimonials={frontmatter.testimonials}
        intro={frontmatter.intro}
      />
    </Layout>
  )
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        intro {
          title
          subtitle
          item1 {
            title
            description
          }
          item2 {
            title
            description
          }
          item3 {
            title
            description
          }
        }
        testimonials {
          title
          subtitle
          list {
            text
            name
          }
        }
        questions {
          title
          subtitle
          list {
            title
            text
          }
          image {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        banner {
          title
          btnTitle
          phone
          bannerImage {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
